export enum WorkPackageBuiltStatus {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED"
}

export const NOT_STARTED = WorkPackageBuiltStatus.NOT_STARTED;
export const IN_PROGRESS = WorkPackageBuiltStatus.IN_PROGRESS;
export const COMPLETED = WorkPackageBuiltStatus.COMPLETED;

export default WorkPackageBuiltStatus;