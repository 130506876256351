import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { ListSubheader, MenuItem } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import downloadFiltered5dTsv from "../../actions/wbs_page/download_filtered_5d_tsv";
import getExportStarted from "../../services/getters/get_export_started";
import getMasterformatSelections from "../../services/getters/wbs_getters/get_masterformat_selections";
import NavBarKebabMenu from "./nav_bar_kebab_menu";
import useAvvirSelector from "../../services/component_helpers/component_effects/use_avvir_selector";

import type { Dispatch } from "type_aliases";

type DispatchedEvents = ReturnType<typeof downloadFiltered5dTsv>;
export const NavBarControlsWbs: FunctionComponent = () => {
  const { download5DTsv, masBranding2, updated5DExport } = useFlags();
  const dispatch = useDispatch() as Dispatch<DispatchedEvents>;
  const exportStarted = useAvvirSelector(getExportStarted);
  const filter = useAvvirSelector(getMasterformatSelections);
  const exports: React.JSX.Element[] = [];
  if (download5DTsv && !updated5DExport) {
    exports.push(<ListSubheader
      key="NavBarKebabMenu-ExportsMenuHeading"
      disableSticky
      disableGutters
      className="NavBarKebabMenu-label"
      role="heading"
    >
      Exports
    </ListSubheader>);
    exports.push(<MenuItem
      key="NavBarKebabMenu-5dTsvExportMenuItem"
      className="NavBarKebabMenu-5dTsv NavBarKebabMenu-menuItem"
      disabled={exportStarted}
      onClick={() => dispatch(downloadFiltered5dTsv(filter, masBranding2 ? "CAP-5D-Report-" : "Avvir-5D-Report-"))}
    >
      Visible Line Items (.tsv)
    </MenuItem>);
  }

  return <NavBarKebabMenu>
    {exports}
  </NavBarKebabMenu>;
};

export default NavBarControlsWbs;
