import { ApiProjectAreaProgress } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import DateConverter from "../../../services/converters/date_converter";
import WorkPackageBuiltStatus from "../enums/work_package_built_status";
import { DateLike, ModifyPartial } from "type_aliases";

type ProjectAreaProgressParameters = ModifyPartial<ProjectAreaProgress, {
  expectedStart: DateLike,
  expectedCompletion: DateLike,
  start: DateLike,
  completion: DateLike
}>

export default class ProjectAreaProgress {
  constructor({
    id,
    name,
    status,
    start,
    completion,
    expectedStart,
    expectedCompletion
  }: ProjectAreaProgressParameters = {}) {
    addDateGetterAndSetterToDomainModel(this, "expectedStart", expectedStart);
    addDateGetterAndSetterToDomainModel(this, "expectedCompletion", expectedCompletion);
    addDateGetterAndSetterToDomainModel(this, "start", start);
    addDateGetterAndSetterToDomainModel(this, "completion", completion);
    this.id = id || null;
    this.name = name || null;
    this.status = status;
  }

  id: number;
  readonly name: string;
  status: WorkPackageBuiltStatus;
  expectedStart: Date;
  expectedCompletion: Date;
  start: Date;
  completion: Date;


  static fromApi = (apiProjectAreaProgress: ApiProjectAreaProgress): ProjectAreaProgress => {
    const status: WorkPackageBuiltStatus = !apiProjectAreaProgress.status ?
                                           WorkPackageBuiltStatus.NOT_STARTED :
                                           WorkPackageBuiltStatus[apiProjectAreaProgress.status.toUpperCase()];
    const start = DateConverter.localDateToMoment(apiProjectAreaProgress.start)?.toDate();
    const completion = DateConverter.localDateToMoment(apiProjectAreaProgress.completion)?.toDate();
    const expectedStart = DateConverter.localDateToMoment(apiProjectAreaProgress.expectedStart)?.toDate();
    const expectedCompletion = DateConverter.localDateToMoment(apiProjectAreaProgress.expectedCompletion)?.toDate();
    return new ProjectAreaProgress({ ...apiProjectAreaProgress, status, start, completion, expectedStart, expectedCompletion });
  };

  static toApi = (projectAreaProgress: ProjectAreaProgress): ApiProjectAreaProgress => {
    const start = DateConverter.dateToLocalDate(projectAreaProgress.start);
    const completion = DateConverter.dateToLocalDate(projectAreaProgress.completion);
    const expectedStart = DateConverter.dateToLocalDate(projectAreaProgress.expectedStart);
    const expectedCompletion = DateConverter.dateToLocalDate(projectAreaProgress.expectedCompletion);
    return new ApiProjectAreaProgress({
      id: projectAreaProgress.id,
      status: projectAreaProgress.status,
      name: projectAreaProgress.name,
      start,
      completion,
      expectedStart,
      expectedCompletion
    });
  };
}
