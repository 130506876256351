import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Paper, SvgIcon } from "@mui/material";
import { UserRole } from "avvir";

import "../../../css/nav_bar/nav_bar.css";
import * as AvvirLogo from "../../../resources/logo/logo_icon_source.svg";
import * as HexagonLogo from "../../../resources/logo/hexagon-wordmark.svg";
import getPageTitle from "../../services/getters/get_page_title";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getUserIsLoggedIn from "../../services/getters/user_getters/get_user_is_logged_in";
import getUserRole from "../../services/getters/user_getters/get_user_role";
import Link from "../../vendor/redux-first-router-link";
import NavBarPagesDropdown from "./nav_bar_pages_dropdown";
import NavPageControls from "./nav_bar_page_controls";
import Tooltipable from "../tooltipable";
import toProjectPortfolioPage from "../../events/routing/to_project_portfolio_page";
import toSuperadminOrganizations from "../../events/routing/superadmin/to_superadmin_organizations";
import { useFlags } from "launchdarkly-react-client-sdk";

const AvvirLogoIcon = <SvgIcon className="NavBar-logo" component={AvvirLogo.ReactComponent} viewBox="0 0 64 64"/>;
const HexagonLogoIcon = <SvgIcon className="NavBar-logo" component={HexagonLogo.ReactComponent} viewBox="0 0 46.745 14.487"/>;

export type Props = ReturnType<typeof mapStateToProps>;

export const NavBar: FunctionComponent<Props> = (props) => {
  const {
    userIsLoggedIn,
    projectId,
    pageTitle,
    userRole,
  } = props;

  const { masBranding2 } = useFlags();

  let logoLink, logoTooltip;
  if (userRole === UserRole.SUPERADMIN) {
    logoLink = toSuperadminOrganizations();
    logoTooltip = "Go to Organizations";
  } else {
    logoLink = toProjectPortfolioPage();
    logoTooltip = "Go to your project portfolio";
  }

  const logo = masBranding2 ? HexagonLogoIcon : AvvirLogoIcon;
  const logoLabel = masBranding2 ? "Hexagon Logo" : "Avvir Logo";
  if (userIsLoggedIn) {
    return <Paper
      className="NavBar"
      square
    >
      <Tooltipable title={logoTooltip}>
        <Link className="NavBar-logo" to={logoLink} aria-label={logoLabel}>
          {logo}
        </Link>
      </Tooltipable>
      {projectId ? <>
        <span className="NavBar-pageName" aria-label="Project Name">{pageTitle}</span>
        <NavBarPagesDropdown/>
      </> : <span className="NavBar-pageName" aria-label="Page Title">{pageTitle}</span>}
      <NavPageControls/>
    </Paper>;
  } else {
    return <Paper
      square
      className="NavBar"
    >
      {logo}
    </Paper>;
  }
};

const mapStateToProps = (state, props) => ({
  userIsLoggedIn: getUserIsLoggedIn(state, props),
  projectId: getProjectId(state, props),
  pageTitle: getPageTitle(state, props),
  userRole: getUserRole(state, props),
});

export default connect(mapStateToProps)(NavBar);

