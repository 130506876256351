import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { throttle } from "underscore";
import { datadogLogs } from "@datadog/browser-logs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useFlags, withLDProvider } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Avvir from "avvir";

import Application from "./routers/application";
import eventTrackingMiddleware from "../services/mixpanel_services/event_tracking_middleware";
import rootReducer from "../services/reducers/root_reducer";
import signOutUser from "../actions/sign_in_page/sign_out_user";
import theme from "../style/mui_theme";
import undoMiddleware from "../services/undo_middleware";
import UtilityContext from "./utility_context";
import { CUSTOM_FAILURE_MESSAGE_DISPLAYED } from "../events/notifications/failures/custom_failure_message_displayed";
import { getSelectionToSave } from "../services/reducers/reduce_selection";
import { loadStore, saveStore } from "../services/utilities/store_in_local_storage";
import { routesEnhancer, routesMiddleware } from "../router_redux_config";

// BEGIN temporary debugging
const observer = new MutationObserver((list, observer) => {
  for (const event of list) {
    const newValue = (event.target as HTMLHtmlElement).attributes["lang"].value;
    throw new Error(`Language changed from "${event.oldValue}" to "${newValue}"`);
  }
});
observer.observe(document.documentElement, {attributes:true, attributeFilter:["lang"], attributeOldValue:true});
// END temporary debugging

const AVVIR_ENV = process.env.REACT_APP_AVVIR_ENVIRONMENT;
console.info(`Avvir Environment: ${AVVIR_ENV}`);
Avvir.api.config.setConfigurationForEnvironment(AVVIR_ENV);
Avvir.api.config.getConfiguration().AVVIR_GATEWAY_URL = process.env.REACT_APP_WEB_API_GATEWAY_DOMAIN;

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_API,
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "avvir-web",
  env: `node_${process.env.NODE_ENV}:gateway_${AVVIR_ENV}`,
  //@ts-ignore
  forwardConsoleLogs: ["warn", "error"]
});

const composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
                         ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({
    actionSanitizer: (event) => {
      if (event?.payload?.mesh) {
        return {
          ...event,
          payload: {
            ...event.payload,
            mesh: "<<THREE.js Mesh Object>>"
          }
        };
      } else {
        return event;
      }
    }
  })
                         : compose;

const expiredJwtTokenMiddleware = store => next => action => {
  const state = store.getState();
  const expTime = state.user?.account?.expireTime || Infinity;
  if (expTime < Infinity) {
    const now = Date.now();
    const expired = now > expTime * 1000;
    if (expired) {
      return signOutUser()(next).then(() => {
        return next({
          type: CUSTOM_FAILURE_MESSAGE_DISPLAYED,
          payload: {
            message: "Login Expired: Please login again"
          }
        });
      });
    }
  }
  return next(action);
};
const store = createStore(rootReducer, loadStore(), composeEnhancers(
  routesEnhancer,
  applyMiddleware(expiredJwtTokenMiddleware, thunk, routesMiddleware, eventTrackingMiddleware, undoMiddleware)
));

store.subscribe(throttle(() => {
  saveStore({
    selection: getSelectionToSave(store.getState().selection)
  });
}, 1000)); // only try to serialize the store at most once per second

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to never refetch data;
      staleTime: Infinity,
      // globally default to not retry failed queries
      retry: false
    }
  }
});

const AvvirApp = () => {
  const { redirectAllRequests2 } = useFlags();

  if (redirectAllRequests2) {
    const url = document.location.pathname + document.location.search + document.location.hash;
    window.location.href = "https://cap.hexagon.com" + url;
  }

  let app = <MuiThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <UtilityContext/>
          <Application/>
          <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
      </Provider>
    </LocalizationProvider>
  </MuiThemeProvider>;

  return app;
};

export default React.memo(withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
  user: {
    key: "anonymous",
    anonymous: true
  }
})(AvvirApp));
